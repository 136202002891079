import { _mock } from './_mock';

// ----------------------------------------------------------------------

export const _categories = [
  { label: 'Marketing', path: '' },
  { label: 'Community', path: '' },
  { label: 'Tutorials', path: '' },
  { label: 'Business', path: '' },
  { label: 'Management', path: '' },
];

// ----------------------------------------------------------------------

export const _testimonials = [...Array(8)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.fullName(index),
  role: _mock.role(index),
  avatarUrl: _mock.image.avatar(index),
  createdAt: _mock.time(index),
  ratingNumber: 5,
  review:
    'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
}));

// ----------------------------------------------------------------------

export const _socials = [
  // {
  //   value: 'facebook',
  //   label: 'FaceBook',
  //   icon: 'carbon:logo-facebook',
  //   color: '#1877F2',
  // },
  // {
  //   value: 'instagram',
  //   label: 'Instagram',
  //   icon: 'carbon:logo-instagram',
  //   color: '#E02D69',
  // },
  {
    value: 'linkedin',
    label: 'Linkedin',
    icon: 'carbon:logo-linkedin',
    color: '#007EBB',
  },
  // {
  //   value: 'twitter',
  //   label: 'Twitter',
  //   icon: 'carbon:logo-twitter',
  //   color: '#00AAEC',
  // },
];

// ----------------------------------------------------------------------

const LAT_LONG = [
  [33, 65],
  [-12.5, 18.5],
  [20.96, 26.27],
];

export const _offices = ['Jordan', 'Canada', 'Portugal'].map((office, index) => ({
  id: _mock.id(index),
  country: office,
  address: _mock.fullAddress(index),
  phoneNumber: _mock.phoneNumber(index),
  email: _mock.email(index),
  photo: _mock.image.travel(index + 4),
  latlng: LAT_LONG[index],
}));

// ----------------------------------------------------------------------

const BRANDS_NAME = [
  'airbnb',
  'dropbox',
  'facebook',
  'google',
  'heroku',
  'lenovo',
  'microsoft',
  'netflix',
  'slack',
  'spotify',
  'tripadvisor',
  'vimeo',
];

export const _brands = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}.svg`,
}));

export const _brandsColor = BRANDS_NAME.map((brand, index) => ({
  id: _mock.id(index),
  name: brand,
  image: `/assets/logo/${brand}_original.svg`,
}));

// ----------------------------------------------------------------------
const faqAnswers = [
  "There are various campaign types under performance marketing, including Pay-Per-Click (PPC), Cost-Per-Acquisition (CPA), Cost-Per-Lead (CPL), and Affiliate Marketing. The best campaign type often depends on your business goals, whether it's increasing brand awareness, driving sales, or generating leads.",
  'We utilize advanced tracking technologies and analytics tools to monitor every aspect of a campaign. This includes tracking clicks, conversions, user behavior, and ROI. With real-time data at our fingertips, we can optimize campaigns on-the-fly to ensure the best results.',
  'Unlike traditional advertising where you pay for exposure regardless of the results, in performance marketing, you only pay when a specific action occurs. This model ensures that your investment yields measurable outcomes, be it a sale, lead generation, or website visit.',
  'Your budget should align with your business goals and the potential ROI you aim to achieve. We recommend starting with a test campaign to gauge performance. From there, we can analyze the results and adjust budgets and strategies accordingly to maximize your return.',
];

export const _faqs = [
  'What types of campaigns can be run with performance marketing?',
  'How do you track the success of a campaign?',
  'How does performance marketing differ from traditional advertising?',
  'How do I determine my budget for a performance marketing campaign?',
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer: faqAnswers[index],
}));

export const _faqsSupport = [
  `[Covid] Seasonal Shopping Guide`,
  'I Want To Check Where My Order Is Delivered',
  '[Shipping Information] How To Contact The Shipping Unit/Look Up Shipping Information/Delivery Exchange?',
  '[Seller] Start Selling With Shopee',
  'Why Is My Account Locked/Limited?',
  'Free Shipping Code User Guide (Freeship Code)',
  'How To Buy / Order On Shopee App',
  `Why I Didn't Receive the Verification Code (OTP)?`,
  `Frequently Asked Questions About Product Reviews / Comments`,
  `How to Login Shopee Account When Forgot/Lost Password`,
].map((question, index) => ({
  id: _mock.id(index),
  question,
  answer:
    'Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I appreciate there attitude and approach.',
}));
