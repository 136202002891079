import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Brightness3Icon from '@mui/icons-material/Brightness3'; // Moon icon
import WbSunnyIcon from '@mui/icons-material/WbSunny'; // Sun icon
import Stack from '@mui/material/Stack';

import { useSettingsContext } from 'src/components/settings';

import NavList from './nav-list';

// ----------------------------------------------------------------------

export default function NavDesktop({ data, sx }) {
  const settings = useSettingsContext();

  const darkMode = settings.themeMode === 'dark';

  const toggleDarkMode = () => {
    settings.onUpdate('themeMode', settings.themeMode === 'dark' ? 'light' : 'dark');
  };

  return (
    <Stack
      component="nav"
      direction="row"
      spacing={5}
      sx={{
        height: 1,
        ...sx,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={toggleDarkMode}>
          {darkMode ? <WbSunnyIcon fontSize="small" /> : <Brightness3Icon fontSize="small" />}
        </IconButton>
      </div>

      {data.map((link) => (
        <NavList key={link.title} item={link} />
      ))}
    </Stack>
  );
}

NavDesktop.propTypes = {
  data: PropTypes.array,
  sx: PropTypes.object,
};
